<template>
    <main class="home">
         <section class="content-home-banner">
            <div class="content-home-banner-itens container">
               <div class="row">
                  <div class="col-lg-6">
                     <div class="content-image">
                        <img class="img-fluid" src="@/assets/img/home/image-banner-home.png" alt="image banner home" title="image banner home"/>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="content-text">
                        <h1 class="title" 
                        v-content="{section: 'Home', removeHtml: true}">Adere a este desafio e ganha PRÉMIOS em todas as campanhas</h1>
                        <p v-content="{section: 'Home', removeHtml: true}">
                           No mais Horeca somos mais que meras palavras. Queremos premiar, de verdade, o esforço de todos os colegas, 
                           com prémios relevantes e para o dia-a-dia. Regista-te, gera leads reais e ganha prémios praticamente todos os meses. 
                           Trabalhar e ganhar nunca foi tão fácil!
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="content-home-campaigns">
            <div class="content-home-campaigns-itens container">
               <div class="content-home-campaigns-itens-header">
                  <h2 v-content="{section: 'Home', removeHtml: true}">Campanhas em vigor</h2>
               </div>
               <div class="content-home-campaigns-itens-body">
                  <template v-for="(campaign, key) in campaigns">
                     <router-link :key="key" class="content-elements"
                        :to="{name: 'campaign', params: {
                           slug: campaign.slug
                        }}">
                        <div class="content-elements-image">
                           <img class="img-fluid" :src="campaign.image_path" alt="image campaigns" title="image campaigns"/>
                        </div>
                        <div class="content-elements-text">
                           <div v-if="campaign.user_rank && campaign.user_rank.rank > 0" class="content-elements-text-ranking">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.251" height="21.244" viewBox="0 0 21.251 21.244">
                                 <defs>
                                    <clipPath id="a">
                                       <rect width="21.251" height="21.244" fill="#fff"/>
                                    </clipPath>
                                 </defs>
                                 <g opacity="0.7">
                                    <g clip-path="url(#a)">
                                       <path d="M112.129,187.046a.829.829,0,0,1-.5-.862c.009-3.276.005-6.552.005-9.828,0-.575.245-.823.817-.823h7.236c.57,0,.818.251.818.824,0,3.276,0,6.552.005,9.828a.823.823,0,0,1-.5.862Zm4.606-5.744c0-.47-.016-.94,0-1.409.024-.552-.342-.939-1.035-.786a.652.652,0,0,0-.387,1.029.5.5,0,0,1,.086.26c.006.788,0,1.575.009,2.363a.955.955,0,0,0,.122.448.629.629,0,0,0,.724.268.678.678,0,0,0,.475-.658c.006-.5,0-1.009,0-1.513" transform="translate(-105.442 -165.802)" fill="#fff"/>
                                       <path d="M.5,277.547a.79.79,0,0,1-.5-.833c.016-1.548.006-3.1.007-4.645a.653.653,0,0,1,.681-.72c1.362-.009,2.723,0,4.085,0a.818.818,0,0,1,.108.018v.263c0,1.645-.006,3.29,0,4.935,0,.328.065.656.1.983Z" transform="translate(0 -256.303)" fill="#fff"/>
                                       <path d="M293.539,307.567c.035-.335.1-.669.1-1,.013-1.051,0-2.1,0-3.152v-.279h.258c1.244,0,2.489.01,3.733-.006a.841.841,0,0,1,.884.5v3.444a.86.86,0,0,1-.5.5Z" transform="translate(-277.268 -286.323)" fill="#fff"/>
                                       <path d="M374.381,374.76a.86.86,0,0,0,.5-.5v.5Z" transform="translate(-353.628 -353.516)" fill="#fff"/>
                                       <path d="M123.762,0a.665.665,0,0,1,.646.489c.216.581.431,1.164.636,1.749a.239.239,0,0,0,.248.19c.594.028,1.187.069,1.78.1a.658.658,0,0,1,.632.448.635.635,0,0,1-.2.731q-.712.619-1.436,1.225a.255.255,0,0,0-.1.3c.156.593.3,1.189.453,1.783a.68.68,0,0,1-1.081.775c-.475-.322-.953-.639-1.42-.971a.246.246,0,0,0-.34.006c-.474.334-.957.657-1.437.981a.672.672,0,0,1-.827.035.688.688,0,0,1-.244-.793c.15-.595.292-1.192.457-1.782a.3.3,0,0,0-.123-.368c-.457-.38-.908-.768-1.358-1.156a.657.657,0,0,1-.239-.773.679.679,0,0,1,.679-.443c.58-.031,1.159-.075,1.739-.1a.238.238,0,0,0,.243-.2c.2-.578.417-1.154.629-1.729a.671.671,0,0,1,.657-.5" transform="translate(-113.131 0)" fill="#fff"/>
                                    </g>
                                 </g>
                              </svg>
                              <p>{{campaign.user_rank.rank}}º</p>
                           </div>
                           <div class="content-elements-text-description">
                              <div class="content-elements-text-description-header">
                                 <h2>{{campaign.name}}</h2>
                                 <p>{{campaign.date_start_at|moment('DD/MM/YYYY')}} - {{campaign.date_end_at|moment('DD/MM/YYYY')}}</p>
                              </div>
                              <div class="content-elements-text-description-body">
                                 <p v-html="campaign.description"></p>
                              </div>
                              <div class="content-link" >consultar</div>
                           </div>
                        </div>
                     </router-link>
                  </template>
               </div>
            </div>
         </section>
         <section v-if="histories.length" class="content-home-historic-campaigns">
            <div class="content-home-historic-campaigns-itens container">
               <div class="content-home-historic-campaigns-itens-header">
                  <h2>Histórico de campanhas </h2>
               </div>
               <div class="content-home-historic-campaigns-itens-body">
                  <div class="row">
                    
                     <div v-for="(campaign, key) in histories"
                        :key="key"
                        class="col-12 col-md-6 col-lg-4">
                        <router-link class="content-card-historic" 
                           :to="{name: 'campaign', params: {
                           slug: campaign.slug
                        }}">
                           <div class="content-card-historic-image">
                              <img class="img-fluid" :src="campaign.image_path" alt="image historic campaigns" title="image historic campaigns"/>
                           </div>
                           <div class="content-card-historic-div">
                              <div class="content-card-historic-div-header">
                                 <h2>{{campaign.name}}</h2>
                                 <p>{{campaign.date_start_at|moment('DD/MM/YYYY')}} - {{campaign.date_end_at|moment('DD/MM/YYYY')}}</p>
                              </div>
                              <div class="content-card-historic-div-text">
                                 <p v-html="campaign.description"></p>
                              </div>
                              <p class="link-consult">consultar</p>
                           </div>
                        </router-link>
                     </div>
                   
                  </div>
                  <router-link class="btn-view-historic" :to="{name: 'campaigns'}">
                  Ver histórico
                  </router-link>
               </div>
            </div>
         </section>
      </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            campaigns : [],
            histories: [],
        }
    },
    computed:{
        ...mapGetters('App', ['recaptchaKey']),
        ...mapGetters('User', ['user']),
    },
    methods:{
        ...mapActions('App', ['loading']), 
        ...mapActions('User', ['getUser']), 
        async listCampaigns(){
            this.loading(true);            
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/list-all',
                params: { is_active: 1, user_id: this.user.id}             
            }).then(response => response.data.data)
            .then((data) => {                 
               this.loading(false);                   
               this.campaigns = data;               
            })            
        },
        async listHistory(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/list-all',
                params: {
                   is_active: 0,
                   limit: 3
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false);              
               this.histories = data.data;               
            })            
        },
    },
    async mounted(){    
        this.$nextTick(async () => {
           if(localStorage.getItem("maishoreca_token") && Object.keys(this.user).length == 0){
              await this.getUser();
           }
           await this.listCampaigns();
           await this.listHistory();
        })
    }
}
</script>
<style>
.content-home-banner{
   background-image: url("~@/assets/img/home/banner_home.png");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   padding: 50px 0;
}
</style>